/* PROD : */

/*
export const API_URL = 'https://shograti.herokuapp.com/api/'
export const BACKEND_URL = 'https://shograti.herokuapp.com'
*/


/* PROD 2 : */

export const API_URL = 'https://api.ubeton.fr/api/'
export const BACKEND_URL = 'https://api.ubeton.fr'

/* DEV : */

/*  
export const API_URL = 'http://localhost:3002/api/'
export const BACKEND_URL = 'http://localhost:3002' 
*/
